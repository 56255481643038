import { Prodotto } from "../../generated/api";
import { api } from "../../shared/api";
import { catchErrorHandlerApi } from "../../shared/api-utilis";
import { prodottoState } from "./prodotto.state";

export const setPropotto = (prodotto: Prodotto) => {
  prodottoState.prodotto = prodotto;
};

export const setClientId = (clientId: string | undefined) => {
  prodottoState.clientId = clientId;
};

export const resetPropotto = () => {
  prodottoState.prodotto = null;
};

export const setLoadedPropotto = () => {
  prodottoState.loaded = true;
};

export const setUnloadedPropotto = () => {
  prodottoState.loaded = false;
};

export const loadProdottoAction = (code: string): void => {
  setUnloadedPropotto();
  api.prodotto
    .getProdotto(code)
    .then((prodotto) => {
      setPropotto(prodotto.data);
      setLoadedPropotto();
    })
    .catch(catchErrorHandlerApi);
};
